$(function() {
    $.scrollify({
        section: ".section",
        scrollbars: false,
        before: function(index)
        {
            items = $('.section');
            item = $($('.section').get(index));

            items.removeClass('selected');
            item.addClass('selected');
            var current = $.scrollify.currentIndex();
            if(current > 0) {
                $('.nav-home').addClass('on');
                $('.overlay').hide();
            }
            if(current == 0) {
                $('.nav-home').removeClass('on');
                $('.overlay').show();
            }
            if(current == 1) { // index 1 -> item2 on
                $('#item1').css('marginTop', '0vh');
                $('nav div').removeClass('premiere-position').removeClass('troisieme-position');

                $('#item1').addClass('premiere-position').addClass('semi-transparent').removeClass('transparent');
                $('#item2').removeClass('transparent').removeClass('semi-transparent'); // ON
                $('#item3').addClass('troisieme-position').addClass('semi-transparent').removeClass('transparent');
                $('#item4').removeClass('semi-transparent').addClass('transparent');
                $('#item5').removeClass('semi-transparent').addClass('transparent');
                $('#item6').removeClass('semi-transparent').addClass('transparent');
            }
            if(current == 2) { // index 2 -> item3 on
                if ($( window ).width() > 600) {
                    if ($( window ).width() > 3800) {
                        $('#item1').css('marginTop', '-8vh');
                    } else if ($( window ).width() > 2500) {
                        $('#item1').css('marginTop', '-12vh');
                    } else if ($( window ).width() >= 1366) {
                        $('#item1').css('marginTop', '-22vh');
                    } else {
                        $('#item1').css('marginTop', '-18vh');
                    }
                } else {
                    $('#item1').css('marginTop', '-11vh');
                }
                $('nav div').removeClass('premiere-position').removeClass('troisieme-position');

                $('#item1').removeClass('semi-transparent').addClass('transparent');
                $('#item2').addClass('premiere-position').addClass('semi-transparent').removeClass('transparent');
                $('#item3').removeClass('transparent').removeClass('semi-transparent'); // ON
                $('#item4').addClass('troisieme-position').addClass('semi-transparent').removeClass('transparent');
                $('#item5').removeClass('semi-transparent').addClass('transparent');
                $('#item6').removeClass('semi-transparent').addClass('transparent');
            }
            if(current == 3) { // index 3 -> item4 on
                if ($( window ).width() > 600) {
                    if ($( window ).width() > 3800) {
                        $('#item1').css('marginTop', '-23vh');
                    } else if ($( window ).width() > 2500) {
                        $('#item1').css('marginTop', '-28vh');
                    } else if ($( window ).width() >= 1366) {
                        $('#item1').css('marginTop', '-44vh');
                    } else {
                        $('#item1').css('marginTop', '-36vh');
                    }
                } else {
                    $('#item1').css('marginTop', '-33vh');
                }
                $('nav div').removeClass('premiere-position').removeClass('troisieme-position');

                $('#item1').removeClass('semi-transparent').addClass('transparent');
                $('#item2').removeClass('semi-transparent').addClass('transparent');
                $('#item3').addClass('premiere-position').addClass('semi-transparent').removeClass('transparent');
                $('#item4').removeClass('transparent').removeClass('semi-transparent');
                $('#item5').addClass('troisieme-position').addClass('semi-transparent').removeClass('transparent');
                $('#item6').removeClass('semi-transparent').addClass('transparent');
            }
            if(current == 4) { // index 4 -> item5 on
                if ($( window ).width() > 600) {
                    if ($( window ).width() > 3800) {
                        $('#item1').css('marginTop', '-38vh');
                    } else if ($( window ).width() > 2500) {
                        $('#item1').css('marginTop', '-43vh');
                    } else if ($( window ).width() >= 1366) {
                        $('#item1').css('marginTop', '-66vh');
                    } else {
                        $('#item1').css('marginTop', '-53vh');
                    }
                } else {
                    $('#item1').css('marginTop', '-54vh');
                }
                $('nav div').removeClass('premiere-position').removeClass('troisieme-position');

                $('#item1').removeClass('semi-transparent').addClass('transparent');
                $('#item2').removeClass('semi-transparent').addClass('transparent');
                $('#item3').removeClass('semi-transparent').addClass('transparent');
                $('#item4').addClass('premiere-position').addClass('semi-transparent').removeClass('transparent');
                $('#item5').removeClass('transparent').removeClass('semi-transparent'); // ON
                $('#item6').addClass('troisieme-position').addClass('semi-transparent').removeClass('transparent');
            }
            if(current == 5) { // index 5 -> item6 on
                if ($( window ).width() > 600) {
                    if ($( window ).width() > 3800) {
                        $('#item1').css('marginTop', '-52vh');
                    } else if ($( window ).width() > 2500) {
                        $('#item1').css('marginTop', '-59vh');
                    } else if ($( window ).width() >= 1366) {
                        $('#item1').css('marginTop', '-87vh');
                    } else {
                        $('#item1').css('marginTop', '-71vh');
                    }
                } else {
                    $('#item1').css('marginTop', '-78vh');
                }
                $('nav div').removeClass('premiere-position').removeClass('troisieme-position');

                $('#item1').removeClass('semi-transparent').addClass('transparent');
                $('#item2').removeClass('semi-transparent').addClass('transparent');
                $('#item3').removeClass('semi-transparent').addClass('transparent');
                $('#item4').removeClass('semi-transparent').addClass('transparent');
                $('#item5').addClass('premiere-position').addClass('semi-transparent').removeClass('transparent');
                $('#item6').removeClass('transparent').removeClass('semi-transparent'); // ON
            }
        }
    });
    $("nav").on("click",".premiere-position", function() {
        $.scrollify.previous();
    });
    $('.scroll').click(function () {
        $.scrollify.next();
    });
    $("nav").on("click",".troisieme-position", function() {
        $.scrollify.next();
    });
});